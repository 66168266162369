.notification-header {
	margin-bottom: 20px;
}

.notification-header h2 {
	color: #334d6e;
	font-size: 18px;
}

.notification-tabs {
	display: flex;
	align-items: center;
}

.notification-body {
	margin-top: 40px;
	height: 250px;
	overflow-y: auto;
	padding-right: 20px;
}

.notification-tabs div {
	padding: 5px 15px;
	font-size: 14px;
	background-color: #F8F8F8;
	border-radius: 15px;
	color: #B4B4B4;
	margin-left: 15px;
	cursor: pointer;
}

.notification-tabs div:first-child {
	margin-left: 0;
}

.notification-tabs div.active-notification-tab {
	color: #fff;
	background-color: #479BD2;
}

.notification-card {
	margin-bottom: 15px;
}

.notification-card-topbar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 0px;
}

.notification-card-body {
	padding-left: 27px;
}

.notification-card-body a {
	text-decoration: none;
	font-size: 12px;
	font-weight: 600;
	color: #479BD2;
}
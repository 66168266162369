.sidebar {
	width: 20vw;
	min-height: 100vh;
	height: 100%;
	background: #fff;
	box-shadow: 2px 0 8px 5px rgba(0, 0, 0, 0.1);

	&__wrapper {
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #555;
	}

	&__logo-div {
		padding: 10px;
		border-bottom: 1px solid #ccc;
	}

	&__logo {
		width: 11vw;
		height: auto;
	}

	&__user-div {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&__user-details {
		margin-top: 5px;
	}

	&__user-name {
		padding: 0 8px;
		font-size: 14px;
	}

	&__menu {
		margin-bottom: 10px;
		display: flex;
	}

	&__menu-active {
		margin-bottom: 10px;
		color: #109cf1;
		display: flex;
	}

	&__link {
		text-decoration: none;
		text-transform: none;
		color: inherit;
		flex: 1;

		span {
			font-size: 14px;
			font-weight: 600;
			color: inherit;
			flex: 1;
		}

		button {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			color: inherit;
			text-transform: capitalize;
		}
	}

	&__funnel-box {
		width: 100%;
		box-sizing: border-box;
		padding: 5px;
		margin: auto;
		border: 2px solid #aaa;
		border-radius: 10px;
		margin-top: 15px;
	}

	&__funnel {
		margin: 10px 0;
	}

	&__funnel-text {
		margin-top: 15px;
		text-align: center;
		font-weight: bold;
	}

	&__ul {
		list-style: none;
		padding: 5px;
		margin-bottom: 10px;
	}

	&__listitem {
		padding: 5px;
		cursor: pointer;
		border-radius: 10px;
		color: #555;
		margin: 5px;
		margin-left: 30px;
		font-weight: 600;
		font-size: 14px;
	}

	&__listitem-active {
		padding: 5px;
		cursor: pointer;
		border-radius: 10px;
		color: #109cf1;
		margin: 5px;
		margin-left: 30px;
		font-weight: 600;
		font-size: 14px;
	}
}

.pre-screening-dashboard {
	flex: 1;
	padding: 20px 20px 0 20px;
	min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.wrapper {
		padding: 20px 20px 0;
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}

.option-item {
	margin-bottom: 10px;
	color: #6b6b6b;
	font-size: 13px;
	font-weight: 500;
	position: relative;
	margin-top: 0;
	flex-basis: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

.option-item > label {
	padding-left: 32px;
	display: block;
	line-height: 24px;
}

.option-item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.option-item .checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 16px;
	width: 16px;
	border: 2px solid #b4b4b4;
	cursor: pointer;
	border-radius: 0;
}

.option-item .checkmark:hover input ~ .checkmark {
	background-color: #ccc;
}

.option-item input:checked ~ .checkmark {
	background-color: #479bd2;
	border-color: #479bd2;
}

.option-item .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.option-item input:checked ~ .checkmark:after {
	display: block;
}

.option-item .checkmark:after {
	left: 5px;
	top: 2px;
	width: 4px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.add-question-btn {
	width: 32px;
	height: 32px;
	background-color: #479bd2;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;

	&:after, &:before {
		content: '';
		height: 3px;
		width: 20px;
		background-color: #fff;
		display: block;
		border-radius: 3px;
		position: absolute;
	}

	&:before {
		transform: rotate(90deg);
	}
}

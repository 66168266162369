.option-item-bot {
	margin-bottom: 10px;
	color: #6b6b6b;
	font-size: 13px;
	font-weight: 500;
	position: relative;
	margin-top: 10px;
	flex-basis: 50%;

	&:last-child {
		margin-bottom: 0;
	}
}

.option-item-question {
	font-size: 18px;
}

@media only screen and (max-width: 768px) {
	.option-item-question {
		font-size: 18px;
	}
}

@media only screen and (max-width: 768px) {
	.option-item-bot {
		flex-basis: 100%;
	}
}

.option-item-bot > label {
	padding-left: 32px;
	display: block;
	line-height: 24px;
	font-size: 20px;
}

@media only screen and (max-width: 768px) {
	.option-item-bot > label {
		font-size: 15px;
	}
}

.option-item-btnbox {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

@media only screen and (max-width: 768px) {
	.option-item-btnbox {
		margin-top: 10px;
	}
}

.option-item-bot input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.option-item-bot .checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 18px;
	width: 18px;
	border: 2px solid #b4b4b4;
	cursor: pointer;
	border-radius: 50%;
}

.option-item-bot .checkmark:hover input ~ .checkmark {
	background-color: #ccc;
}

.option-item-bot input:checked ~ .checkmark {
	background-color: #479bd2;
	border-color: #479bd2;
}

.option-item-bot .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.option-item-bot input:checked ~ .checkmark:after {
	display: block;
}

.option-item-bot .checkmark:after {
	left: 5px;
	top: 2px;
	width: 4px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

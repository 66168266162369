#demoSignInPage {
    display: flex;
    height: 100vh;
    flex-direction: column;

    .demo-sign-in-wrapper {
        .demo-sign-in-left {
            min-width: 400px;
        }
    }
}

@media only screen and (max-width: 600px) {
    #demoSignInPage {
        .demo-sign-in-wrapper {
            .demo-sign-in-left {
                min-width: auto;
            }

            .demo-sign-in-right {
                display: none;
            }
        }
    }
}
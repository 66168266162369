#myAccount {
	flex: 1;
	padding: 20px 20px 0 20px;
	min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.wrapper {
		padding: 35px 40px 50px;
		margin-top: 35px;
		flex: 1;
		background: #fff;
		border-radius: 40px 40px 0 0;
		display: flex;
		flex-direction: column;

		.myaccount-tabs {
			display: flex;
			border-bottom : 1px solid #EBEFF2;
			padding-bottom: 25px;

			> div {
				color: #dadada;
				font-weight: 600;
				font-size: 18px;
				cursor: pointer;

				&.active {
					color: #479BD2;
					pointer-events: none;
				}
			}
		}
	}
}

.quickmenu {
	&__container {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		width: 100%;
		flex: 1 1;
		padding: 20px;
		box-sizing: border-box;
		scrollbar-width: thin;
		scrollbar-color: #90a4ae #cfd8dc;

		&::-webkit-scrollbar {
			width: 11px;
		}

		&::-webkit-scrollbar-track {
			background: #cfd8dc;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #90a4ae;
			border-radius: 6px;
			border: 3px solid #cfd8dc;
		}
	}

	&__card {
		&-container {
			height: max-content;
			width: 100% !important;
			box-sizing: border-box;
			margin: 0 auto;
			margin-bottom: 50px;
			background-color: white;
			padding: 25px;
			border-radius: 25px;
			box-shadow: 5px 5px 10px rgb(0 0 0 / 30%);

			&-flex {
				display: flex;
			}
		}

		&-headingbox {
			margin-left: 15px;
		}

		&-chartbox {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}

		&-rightbox {
			margin: 30px auto;
			display: flex;
			align-items: center;
		}

		&-td {
			padding: 10px 15px;
			font-size: 16px;

			a {
				margin-left: 10px;
				text-decoration: none;
				width: 100%;
				color: black;
				font-size: 16px;
				font-weight: 600;
			}

			svg {
				height: 18px;
				width: 20px;
				transform: scale(1.5) translate(0px, 2.5px);
			}
		}

		&-legend {
			width: 15px;
			height: 15px;
			border-radius: 50%;

			&-invited {
				background-color: rgb(79, 202, 100);
			}

			&-submitted {
				background-color: rgb(84, 197, 235);
			}

			&-shortlisted {
				background-color: rgb(255, 183, 74);
			}

			&-hired {
				background-color: rgb(34, 36, 44);
			}
		}

		&-heading {
			font-size: 19px;
		}

		&-count {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			padding: 6px 0px;
			line-height: 16px;
			color: rgb(134, 142, 150);
		}

		&-fulllist {
			font-size: 19px;
			display: flex;
			align-items: center;
			font-style: normal;
			font-weight: 500;
			color: #479bd2;
		}

		&-link {
			text-decoration: none;
		}

		&-headingrow {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
		}
	}

	&__table {
		&-head {
			span {
				font-family: "Poppins";
				font-size: 16px;
				font-weight: 600;
				color: #334d6e;
			}
		}

		&-cell {
			&:nth-child(1) {
				width: 10%;
			}

			&:nth-child(2) {
				width: 30%;
			}

			&:nth-child(3) {
				width: 30%;
			}

			&:nth-child(4) {
				width: 20%;
			}

			&:nth-child(5) {
				width: 10%;
			}

			span {
				font-family: "Mulish";
				font-size: 14px;
				font-weight: 400;
				color: #404040;
			}
		}
	}

	&__rating {
		&-innerdiv {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5vh;

			& > div {
				margin: 0 5px;
			}
		}

		&-legend {
			width: 8vh;
			height: 8vh;
			border-radius: 50%;
			background: #34bcd4;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.8vh;
		}

		&-text {
			text-align: center;
			font-size: 20px;

			&-primary {
				color: #334d6e;
				font-weight: 600;
			}

			&-secondary {
				color: #888;
				font-weight: 400;
			}
		}
	}
}

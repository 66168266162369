.scrollbar::-webkit-scrollbar{
    display: contents !important;
    width: 6px !important;
    height: 6px !important;
}


.scrollbar::-webkit-scrollbar-thumb{
    background: #8CC0E3 !important;
    border: 5px !important;
    border-radius: 25px !important;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(8, 105, 184, 100%) !important; 
}

.scrollbar::-webkit-scrollbar-corner {
    display: none !important;
}

.scrollbar::-webkit-scrollbar-track{
    border-radius: 25px !important;
    margin: 5px !important;
}
#chatMessageList {
	display: flex;
	flex-direction: column-reverse;
	/* justify-content: end; */
	overflow-y: scroll;
	height: 60vh;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.message-row {
	display: grid;
	grid-template-columns: 70%;
}

.message-content {
	display: grid;
}

.you-message {
	justify-content: end;
}

.you-message .message-content {
	justify-items: end;
}

.other-message {
	justify-items: start;
}

.other-message .message-content {
	grid-template-columns: 48px 1fr;
	grid-column-gap: 15px;
}

.message-row-img {
	border-radius: 100%;
	grid-row: span 2;
	height: 10vh;
	max-height: 40px;
}

.message-time {
	font-size: 1.2vh;
	color: #868686;
}

.message-text {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 5px;
	max-width: 45vw;
	font-weight: 400;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	overflow-wrap: break-word;
}


.you-message .new-img {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 5px;
	max-width: 62vw;
	font-weight: 400;
	-webkit-animation: fadein 1s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s;
	/* Firefox < 16 */
	-ms-animation: fadein 1s;
	/* Internet Explorer */
	-o-animation: fadein 1s;
	/* Opera < 12.1 */
	animation: fadein 1s;


	transform-origin: bottom;
	background: #479BD2;
	color: #FCFCFC;
	border-radius: 25px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.other-message .new-img {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 5px;
	max-width: 62vw;
	font-weight: 400;
	-webkit-animation: fadein 1s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s;
	/* Firefox < 16 */
	-ms-animation: fadein 1s;
	/* Internet Explorer */
	-o-animation: fadein 1s;
	/* Opera < 12.1 */
	animation: fadein 1s;
	background: #FAFAFA;
	color: #262626;
	border-radius: 25px;
	overflow-wrap: normal;
	text-overflow: wrap;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.you-message .new {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 5px;
	max-width: 45vw;
	font-weight: 400;
	-webkit-animation: fadein 1s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s;
	/* Firefox < 16 */
	-ms-animation: fadein 1s;
	/* Internet Explorer */
	-o-animation: fadein 1s;
	/* Opera < 12.1 */
	animation: fadein 1s;


	transform-origin: bottom;
	background: #479BD2;
	color: #FCFCFC;
	border-radius: 25px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.other-message .new {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 5px;
	max-width: 45vw;
	font-weight: 400;
	-webkit-animation: fadein 1s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s;
	/* Firefox < 16 */
	-ms-animation: fadein 1s;
	/* Internet Explorer */
	-o-animation: fadein 1s;
	/* Opera < 12.1 */
	animation: fadein 1s;
	background: #FAFAFA;
	color: #262626;
	border-radius: 25px;
	overflow-wrap: normal;
	text-overflow: wrap;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@keyframes fadein {
	from {
		opacity: 0.4;
	}

	to {
		opacity: 1;
	}
}

.you-message .message-text {
	background: #479BD2;
	color: #FCFCFC;
	border-radius: 25px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.other-message .message-text {
	background: #FAFAFA;
	color: #262626;
	border-radius: 25px;
	overflow-wrap: normal;
	text-overflow: wrap;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.customModal {
	background: #dbdbdb;
	/* max-height: 100vh; */
	max-width: 90vw;
	/* margin: 0; */
	padding: 0;
}

.videoInputModal {
	background: rgb(82, 82, 82);
	max-height: 100vh;
	max-width: 84vw;
	width: 84vw;
	margin: 0;
	padding: 0;
	padding: 20px 40px;
	margin: 10px 0 0 0;
	box-sizing: border-box;
}

.videoInputModal::-webkit-scrollbar {
	display: contents !important;
	width: 6px !important;
	height: 6px !important;
	margin: 50px 0 !important;
}

.videoInputModal::-webkit-scrollbar-track {
	margin: 50px 0 !important;
	border-radius: 5px !important;
}

.videoInputModal::-webkit-scrollbar-corner {
	display: none !important;
}

.videoInputModal::-webkit-scrollbar-thumb {
	background: #8CC0E3 !important;
	border: 5px !important;
	border-radius: 25px !important;
	height: 10px !important;
}

.videoInputModal-container .react-responsive-modal-container {
	overflow-y: hidden !important;
}

.videoInputModal-container .react-responsive-modal-root {
	right: 1vw !important;
	left: auto;
}

.videoInputTimer svg {
	opacity: 0;
}

.videoInputTimer .timer .minutesvalue .secondsvalue {
	font-size: 14px !important;
}

.videoInputTimer .timer {
	font-size: 14px !important;
}

.videoInputModalOverlay {
	background: rgba(0, 0, 0, 0.2);
}

.videoInput-question {
	background: #fff;
	padding: 20px;
	flex: 1;
	border-radius: 25px;
	color: #479BD2;
	font-size: 14px;
	margin-left: 20px;
}

.videoInput-hr {
	height: 1px;
	background: #fff;
	border-radius: 25px;
	outline: none;
	border: none;
	width: 450px;
	max-width: 80%;
	margin: 20px auto;
}

.videoInputModalClose {
	fill: #8b8b8b;
	background-color: #fff;
	border-radius: 4px;
	margin: 10px 10px 0 0;
}

.closeIcon {
	fill: #eee;
	background-color: red;
	border-radius: 4px;
}

.closeIcon-large {
	fill: #eee;
	background-color: red;
	border-radius: 50%;
	width: 25px;
	height: 25px;
}

.message-text-parent {
	color: #FCFCFC;
	background: #479BD2;
	opacity: 0.8;
	padding: 5px;
	border-radius: 6px;
	position: relative;
	left: -5px;
	width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	display: flex;
	font-size: 15px;
	margin-bottom: 3px;
}

.reply-text-left {
	background-color: aliceblue !important;
	border-top-left-radius: 7.5px;
	border-bottom-left-radius: 7.5px;
	flex: none;
	width: 100%;
	width: 4px;
	height: 25px;
	margin-right: 2px;
}

.exhibitHover:hover {
	box-shadow: 3px 3px 10px 10px rgba(0, 0, 0, 0.1);
}

.ChatbodyScrollbar::-webkit-scrollbar {
	display: contents !important;
	width: 6px !important;
	height: 6px !important;
}

.ChatbodyScrollbar::-webkit-scrollbar-thumb {
	background: #8CC0E3 !important;
	border: 5px !important;
	border-radius: 25px !important;
}

.ChatbodyScrollbar::-webkit-scrollbar-thumb:hover {
	background: rgba(8, 105, 184, 100%) !important;
}

.ChatbodyScrollbar::-webkit-scrollbar-corner {
	display: none !important;
}

.ChatbodyScrollbar::-webkit-scrollbar-track {
	border-radius: 25px !important;
	margin: 3vh 0 7vh !important;
}
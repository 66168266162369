.toggle-btn {
    background-color: #c4c4c4;
    padding: 2px;
    border-radius: 33px;
    box-shadow: 0px 6px 8px 3px #0000001a inset;
    width: 70px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    align-self: center;
    height: 27px;
    position: relative;
    flex-shrink: 0;

    .toggle-dot {
        box-shadow: -2px 1px 6px 0px #00000040;
        margin: 2px;
        border-radius: 23px;
        height: 23px;
        width: 23px;
        background-image: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
        position: absolute;
        left: 2px;
        transition: all 0.3s ease-in-out;
    }

    &.active {
        background-color: #81DB57;

        .toggle-dot {
            left: 45px;
        }
    }
}

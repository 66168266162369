.driveDetails {
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.inner-div-details {
		h2 {
			font-size: 18px;
		}	
	}

	.userListTitleContainer {
		margin-bottom: 20px;
	}

	
	.keyTask {
		margin: 16px 0;
		padding: 5px 10px;
		border-radius: 20px;
		color: #5A5A5A;
		background-color: #CFECFF;
		display: table;
		font-size: 14px;
	}
}

.accountmenu {
	padding: 20px;
	color: #334d6e;
	font-family: "Poppins";

	.accountmenu-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-family: inherit;
			font-size: 18px;
			font-weight: 700;
		}

		h4 {
			color: #898989;
			font-size: 15px;
			font-weight: 400;
		}
	}

	.accountmenu-ul {
		list-style: none;
		padding-left: 0;
		margin-top: 25px;

		li {
			display: flex;
			align-items: center;
			margin: 10px 0;
			cursor: pointer;

			p {
				margin-left: 10px;
				font-weight: 700;
				font-size: 16px;
				color: inherit;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	.accountmenu-footer {
		margin-top: 20px;
		color: #898989;

		p {
			font-size: 12px;
		}
	}
}

.videobox-header {
   background-color: #fff;
   margin-bottom: 5px;
   border-radius: 25px 25px 0 0;
   padding: 20px;
   margin-top: 20px;
}

.videobox-header p {
   color: #334D6E;
   font-size: 16px;
   font-weight: bold;
}

.videobox-wrapper {
   display: flex;
   flex-direction: row;
   justify-content: center;

   background-color: #fff;
   border-radius: 0 0 25px 25px;
}

.videobox {
   width: 40%;
   padding: 20px;
   margin: 0 10px;

   display: flex;
   flex-direction: column;
}

.questionbox {
   width: 100%;
   margin: auto;
   flex: 1;
   border-radius: 25px;
   background-color: rgba(200,200,200,0.1);
}

.video {
   text-align: center;
}

.video video {
   width: 90%;
   margin: auto;
   height: auto;
   border-radius: 25px;
   margin: 15px 0;
}

.questionbox p {
   color: #334D6E;
   font-size: 14px;
   font-weight: bold;

   padding: 10px 20px;
   /* background-color: rgba(200,200,200,0.1); */
   /* border-radius: 25px; */
}

.card-container {
   max-width: 300px;
   width: 90%;
   margin: auto;
   margin-top: 20px;

   background-color: #fff;
   padding: 20px;
   border-radius: 25px;
   box-shadow: 5px 8px 8px 4px rgba(0,0,0,0.15);
}

.card-container .items {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 5px;
   margin: 5px 0;
}

.items p:first-child {
   width: 80%;
}

items p:last-child {
   width: 20%;
   text-align: center;
}

.card-container hr {
   border: none;
   outline: none;
   height: 3px;
   border-radius: 15px;
   background-color: rgba(150,150,150,0.3);
}

.card-container p {
   font-weight: bold;
}

.card-container p.score {
   font-size: 18px;
}

.card-container p.percent {
   font-size: 30px;
   color: rgb(180,180,180);
}

.card-container p.pitch , p.content, p.pitch-number {
   font-size: 16px;
   color: rgb(100,100,100);
}

.card-container input.score-input {
   font-size: 16px;
   color: rgb(100,100,100);
}

.card-container input.score-input::-webkit-inner-spin-button, 
.card-container input.score-input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.card-container p.pitch-number {
   padding: 0 10px;
}

.card-container p.content {
   width: 50%;
}

.card-container p.input {
   /* width: 50%; */
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-self: center
}

.card-container p.input input {
   display: inline-block;
   width: 50px;
   border-radius: 25px;
   font-weight: bold;
   background-color: rgb(230,230,230);
   border: none;
   outline: none;
   padding: 5px 10px;
   color: rgb(100,100,100);
}

.card-container p.input img {
   display: inline-block;
   width: 30px;
   height: 30px;
   margin-right: 10px;
}

.card-container .items textarea {
   width: 100%;
   height: 80px;
   outline: none;
   border: none;
   border-radius: 25px;
   background-color: rgb(230,230,230);
   border: none;
   outline: none;
   padding: 10px 15px;
   color: rgb(100,100,100);
}

/* .scorebox-item {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 10px 0;
}

.scorebox-item > p {
   width: 33.33%;
   text-align: left;
   font-weight: bold;
}

.scorebox-item input {
   width: 90%;
}

.textarea p {
   font-weight: bold;
   margin: 15px 0;
}

.textarea textarea {
   width: 100%;
} */
.customModal-workflow {
	width: 400px;
	border-radius: 10px;
}

.invitationModal {
	border-radius: 10px;
	padding: 1.2rem 40px;
	min-width: 500px;
}

.invitationModal .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
	border-radius: 25px !important;
}

.input-div-applicant {
	margin: 20px 0 40px;
}

.input-div-applicant input {
	margin: 10px 0;
	padding: 10px 20px;
	width: 350px;
	border-radius: 25px;
	border: 1px solid #aaa;
}

.input-div-applicant p {
	color: red;
	font-size: 12px;
	margin-left: 15px;
}

.button-div {
	display: flex;
	flex-direction: row-reverse;
}

.choose-file-div {
	margin: 20px 0 20px 10px;
}

.MuiDataGrid-columnHeaderWrapper {
	background-color: #fff;
}

.MuiDataGrid-footerContainer {
	display: none;
}

.container-div {
	margin: 0 auto;
}

.search-box {
	width: 45vw;
}

.search-box input {
	width: 100%;
	outline: none;
	border: none;
	padding: 10px 20px;
	border-radius: 17px;
}

.search-box input::placeholder {
	padding-left: 20px;
	font-weight: 500;
	font-size: 15px;
	color: rgb(150, 150, 150);
}


.search-box input:placeholder-shown {
	background-image: url('../../assets/Icons/search.png');
	background-position: 20px 9.5px;
	background-repeat: no-repeat;
}

.inner-div-btn {
	background: #479BD2;
	border-radius: 17px;
	outline: none;
	border: none;
	color: #FFFFFF;
	font-family: Poppins;
	font-style: normal;
	/* font-weight: 600; */
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	padding: 8px;
	margin-top: 10px;
}

#btn1 {
	margin-right: 20px;
}

.cards-container {
	width: 100%;
	overflow-x: scroll;
	margin-bottom: 10px;
	padding: 10px 0 0 0;
}

.cards {
	width: max-content;
	margin: auto;
	border-radius: 25px;
	margin-bottom: 25px;
	display: flex;
	align-items: stretch;
}

.cards>div {
	width: 300px;
}

/* .cards > div {
   flex: 1 25%;
}

@media only screen and (max-width: 1100px) {
   .cards > div {
      flex: 1 50%;
   }
 } */

.reinvite-btn {
	border: none;
	background-color: #479BD2;
	border-radius: 17px;
	color: white;
	padding: 5px 10px;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer {
	text-align: center;
}
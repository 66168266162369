.funnel {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.ellipsis {
		width: 100%;
		background: #0a821e;
		border-radius: 50%;
		position: relative;
		z-index: 2;
	}
	
	.trapezium {
		height: 0;
		border-style: solid;
		border-color: transparent;
		display: flex;
		justify-content: center;
		border-bottom: none;
		cursor: pointer;

		.text {
			line-height: 1.0;
			text-transform: uppercase;
			color: #fff;
			font-weight: bold;
			text-align: center;
		}

		.tooltip {
			font-size: 10px;
			padding: 4px 8px;
			border-radius: 4px;
			background-color: #999;
			color: #fff;
			position: absolute;
			z-index: 5;
			visibility: hidden;
			margin-top: -5px;
		}

		&:hover .tooltip {
			visibility: visible;
		}
	}

	.parrallelogram {
		transform: skew(45deg);
		border-bottom: none;
	}
	
	#trapezium1 {
		border-top-color: #50ca64;
	}
	
	.parrallelogram1 {
		background: #a6580e;
	}
	
	#trapezium2 {
		border-top-color: #329af1;
	}
	
	.parrallelogram2 {
		background: #a6580e;
	}
	
	#trapezium3 {
		border-top-color: #f88e2b;
	}
	
	.parrallelogram3 {
		background: #8c8c8c;
	}
	
	#trapezium4 {
		width: 0px;
		border-top-color: #000;
	}
}

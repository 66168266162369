.select {
	width: 200px;
	position: relative;

	.wrapper {
		margin: 0 50px 0 0;
		color: #000;
		font-size: 14px;
		position: relative;
		cursor: pointer;
		line-height: 30px;
		border-bottom: 1px solid rgb(196, 196, 196);
		color: rgb(133, 133, 133);
		text-align: center;

		&:after {
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			border-top: 2px solid #8b8b8b;
			border-right: 2px solid #8b8b8b;
			position: absolute;
			transform: rotate(135deg);
			right: -40px;
			top: 5px;
		}

		&.open:after {
			transform: rotate(-45deg);
			top: 12px;
		}

		&.empty {
			color: #a6a6a6;
		}
	}

	.dropdown {
		box-shadow: 0px 4px 6px 0px #00000040;
		border-top: none;
		border-radius: 0 0 5px 5px;
		background-color: #fff;
		padding: 10px 0;
		font-size: 14px;
		position: absolute;
		left: 0;
		right: 0;

		.dropdown-item {
			padding: 5px 25px;
			color: #a6a6a6;
			cursor: pointer;

			&:hover {
				background-color: #e9e9e9;
			}
		}
	}
}

#dragAndDropQuestion {
    width: 50vw;

    .drag-and-drop-options-box {
        flex-direction: row;

        .drag-and-drop-option {

            li {
                line-height: 1.2vw;
                margin: 2vh 10px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    #dragAndDropQuestion {
        width: auto;

        .drag-and-drop-options-box {
            flex-direction: column;

            .drag-and-drop-option {
                width: 100%;

                li {
                    line-height: 1.2;
                    margin: 2vh 10px;
                }
            }
        }
    }
}
#addNewDriveModal {
	padding: 20px 40px;
	width: 720px;

	.title {
		font-size: 24px;
		color: #363434;
		font-weight: 700;
		margin-left: 0;
	}

	.subtitle {
		margin-top: 5px;
		font-size: 18px;
		color: #676767;
		font-weight: 700;
	}

	.default-list {
		margin-top: 80px;
		display: flex;
		flex-direction: row;
		padding: 0 5px 10px;
		overflow-x: auto;

		.list-item {
			display: flex;
			flex-direction: column;
			flex: 0 0 calc(25% - 24px);
			text-align: center;
			margin-right: 32px;
			color: #bdbdbd;
			font-size: 14px;
			font-weight: 700;

			.list-item-box {
				text-align: center;
				box-shadow: 0px 4px 4px 0px #00000040;
				border-radius: 12px;
				padding: 30px 15px;
				font-size: 12px;
				color: #787878;
				font-weight: 700;
				white-space: normal;
				background-color: #f9f9f9;
				margin-bottom: 12px;
				cursor: pointer;

				img {
					width: 40px;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.divider {
		text-align: center;
		margin: 25px 0 40px;
		font-size: 20px;
		color: #676767;
		position: relative;

		&:before,
		&:after {
			content: "";
			display: block;
			height: 1px;
			width: calc(50% - 25px);
			background-color: #c4c4c4;
			position: absolute;
			top: 50%;
		}

		&:before {
			right: 0;
		}
	}

	.action {
		cursor: pointer;
		font-size: 20px;
		color: #fff;
		padding: 12px 30px;
		border-radius: 15px;
		background-color: #479bd2;
		font-family: inherit;
		line-height: 1.2;
	}

	input[type="text"],
	textarea {
		font-family: inherit;
		font-size: 16px;
		padding: 16px 50px;
		background-color: #f2f2f2;
		border-radius: 16px;
		border: none;
		outline: none;
		color: #000;
		margin-top: 32px;
		width: 75%;

		&::placeholder {
			color: #90a0b7;
		}
	}

	.default-form .action {
		margin-top: 80px;
	}

	.back-btn {
		margin-right: 32px;
		cursor: pointer;
		padding: 10px 0;

		> div {
			position: relative;
			width: 40px;
			height: 2px;
			background-color: #000;

			&:before,
			&:after {
				content: "";
				display: block;
				height: 2px;
				width: 12px;
				left: -2px;
				position: absolute;
				background-color: #000;
			}

			&:before {
				transform: rotate(45deg);
				top: 4px;
			}

			&:after {
				transform: rotate(-45deg);
				top: -4px;
			}
		}
	}

	.add-role-select {
		flex: 1;
		height: 35px;
		margin: 10px;
		background: rgb(242, 242, 242);
		border-radius: 30px;
		max-width: 420px;
	}

	.add-wf-select {
		flex: 1;
		height: auto;
		margin: 10px;
		background: rgb(242, 242, 242);
		border-radius: 30px;
		max-width: 420px;
	}

	.key-task {
		margin-top: 15px;
		font-size: 12px;
		padding: 6px 12px;
		background-color: #ededed;
		color: #5a5a5a;
		border-radius: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&-activekey {
			background-color: #cfecff;
		}
	}

	.add-video-question {
		.add-video-question-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 20px;
		}

		.add-video-question-form {
			margin-top: 20px;
			padding: 0 20px;

			.add-video-question-item {
				margin-top: 35px;

				.add-video-question-item-title {
					font-size: 16px;
					color: #363740;
					font-weight: 600;
				}

				textarea {
					outline: none;
					width: 100%;
					font-family: inherit;
					font-size: 14px;
					padding: 10px 20px;
					background-color: #f5f5f5;
					border-radius: 14px;
					border: none;
					box-sizing: border-box;
				}
			}
		}
	}

	.create-custom-assessment {
		input[type=text] {
			box-sizing: border-box;
			width: 100%;
			background-color: transparent;
			border-bottom: 1px solid #797979;
			border-radius: 0;
			padding: 16px 0;
		}
	}

	.select {
		background-color: #fff;
		.wrapper {
			color: #000;

			&:after {
				width: 7px;
				height: 7px;
				top: 8px;
				border-top: 2px solid hsl(0, 0%, 80%);
				border-right: 2px solid hsl(0, 0%, 80%);
			}
		}

		.dropdown {
			.dropdown-item {
				padding: 5px 25px;
				color: #000;
				cursor: pointer;
	
				&:hover {
					background-color: #cfecff;
				}
			}
		}
	}
}

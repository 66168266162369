body{
  background-color: #f4f7f8;;
}

.productList{
  flex: 4;
  padding: 20px;
}

.productListItem{
  display: flex;
  align-items: center;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root{
  width: 50vw;
  border-radius: 25px 25px 25px 25px;
}

.add-new-button{
  background-color: #0C95BA;
  color: white;
  border-radius: 25px;
  padding: 5px 15px;
  text-transform: capitalize;

}

.MuiDataGrid-columnHeaderWrapper{
  border-radius: 25px 25px 0px 0px;
}

.MuiDataGrid-root{
  border: none;
}

.productListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.productView{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #0C95BA;
  color: white;
  cursor: pointer;
  margin-left: 20px;
}

.productListDelete{
  color: red;
  cursor: pointer;
}

.first-cell-left-align{
  text-align: left;
}

.inner-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.productAddButton {
  margin-bottom: 1vh;
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: #489bd4;
  color: #fff;
  cursor: pointer;
}

.productTitleContainer{
  display: flex;
  flex-direction: row-reverse;
}

.caseName {
  font-weight: 800;
}

.field {
  display: flex;
  margin: 10px 20px 20px 20px;
}

.field-icon {
  color: white; 
  background: darkblue; 
  padding: 10px; 
  font-size: 30px !important; 
  border-radius: 100%;
}

.field-num {
  font-size: 2.3vh;
  font-weight: 600;
}

.field-name {
  font-size: 1.5vh;
  font-weight: 500;
}

.customModal-workflow {
  border-radius: 34px !important; 
  padding: 20px 40px;
}

.modal-heading-workflow {
  font-size: 1.5rem;
}

.input-div-workflow {
  margin-top: 20px;
  /* height: 250px; */
}

.input-div-workflow input {
  margin: 10px;
  padding: 10px 15px;
  width: 350px;
  border-radius: 25px;
  border: 1px solid #aaa;
  font-size: 17.5px;
}

.button-div-workflow {
  display: flex;
  flex-direction: row-reverse;
}

.top-icon-box {
   /* width: 150px;
   overflow: hidden; */
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 0 20px;
}

.top-icon-box button {
 display: inline-block;
 padding: 8px;
}

.MuiCheckbox-root {
   color: rgb(180,180,180) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
   color: rgb(71, 155, 210) !important;
}
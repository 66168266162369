#chatboxProgressBar {
    .chatbox-timer {
        .chatbox-timer-wrapper {
            display: flex;
            padding: 0px 10px;
            margin: auto 2vw auto auto;
            width: 10vw;
            flex-direction: start;

            .chatbox-timer-box {
                position: relative;
                left: 7vw;
                display: flex;
                margin: auto;

                div[aria-label='Countdown timer'] {

                    .bot-fifth-step {
                        position: absolute;
                        left: -7vw;
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }

    .progress-bar-text {
        width: 15vw;
        padding: 0 10px;
        left: 0;
        font-size: 1.7vh;
    }
}

@media only screen and (max-width: 600px) {
    #chatboxProgressBar {
        .chatbox-timer {
            .chatbox-timer-wrapper {
                width: auto;

                .chatbox-timer-box {
                    left: 0;

                    div[aria-label='Countdown timer'] {
                        width: auto !important;
                        height: auto !important;

                        > svg {
                            display: none;
                        }

                        > div {
                            position: relative !important;
                        }

                        .bot-fifth-step {
                            position: relative;
                            left: 0;
                            font-size: inherit;
                        }
                    }
                }
            }
        }

        .progress-bar-text {
            width: 30vw;
            padding: 0;
            left: 3px;
            font-size: 12px;
        }
    }
}
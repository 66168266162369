.paper {
	width: 60vh;
	margin: 1rem auto;
	padding: 2rem;
	background: #fff;
	border-radius: 30px !important;
	box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.2);

}

.signinInput {
	border: none;
	outline: none;
	background-color: rgb(223, 223, 223);
	padding: 15px 12px;
	border-radius: 25px;
	width: 100%;
	max-width: 400px;
	box-sizing: border-box;
}

.signinRightText h1 {
	margin: 10px 0;
	color: #fff;
	font-weight: 800;
	font-size: 38px;
}

.signinRightText h2 {
	background-color: #fff;
	color: rgb(0, 128, 149);
	padding: 8px 15px;
	box-sizing: border-box;
}

.demoinputContainer label,
.signininputContainer label {
	font-weight: 600 !important;
	font-family: "Poppins";
}

.demoinput label,
.signinInput label {
	color: rgba(0, 0, 0, 0.54) !important;
}

.sideImage {
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: -100;
}

@media (max-width: 600px) {
	.sideImage {
		display: none;
	}

	.paper {
		width: 85vw;
	}
}
.range-slider {
    width: 200px;
    position: relative;

    .filled-value {
        width: calc(50% + 6px);
        height: 10px;
        border-radius: 5px;
        background-color: #00A980;
        left: 0;
        bottom: calc(50% - 5px);
        position: absolute;
        z-index: 1;
    }
    
    .empty-value {
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background: #ccc;
        position: absolute;
        bottom: calc(50% - 5px);
    }
    
    .range-slider-range {
        -webkit-appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background: #ccc;
        outline: none;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 3;
        opacity: 0;
        cursor: pointer;
    }
    
    .range-slider-value {
        display: inline-block;
        position: absolute;
        color: #000;
        line-height: 20px;
        text-align: center;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        font-size: 12px;
    }
    
    .range::-ms-tooltip {
        display: none;
    }
    
    .range-thumb {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        position: absolute;
        left: calc(50% - 4px);
        bottom: calc(50% - 4px);
        z-index: 2;
    }
    
    .range-min-value {
        position: absolute;
        left: 0;
        top: 20px;
        font-size: 10px;
        font-weight: 500;
        color: #000;
    }
    
    .helper-text {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        top: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #707070;
    }
    
    .range-max-value {
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 10px;
        font-weight: 500;
        color: #000;
    }
}
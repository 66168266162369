.paper {
    width: 700px;
    margin: 0 auto;
    padding: 20px 0;
    background: #fff;
    border-radius: 1rem !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: left;

}

.paper ul {
   padding-left: 0;
}

.hr {
   width: 100%;
   outline: none;
   border: none;
   background: #aea3b7;
   height: 1.5px;
}

.icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userIcon {
    width: 100px;
    height: 100px;

}

.label {
    margin-bottom: 0.5rem;

}

.info {
    margin-bottom: 0.5rem;
    color: #3f1665;
    text-decoration: underline;
    text-decoration-color: #aea3b7;
}

.button {
    color: #fff !important;
    font-size: 0.7rem !important;
    font-weight: bold;
    text-transform: none !important;
    border-radius: 5px !important;
    /* padding: 4px 1rem !important; */

    /* padding: 4px 20px !important; */
}

@media (max-width: 768px) {
    .paper {
        padding: 0.75rem !important;
        width: 80vw;

        /* over */
    }
}



@media(max-width: 580px) {

    .label,
    .info {
        font-size: 1rem;
    }

    .userIcon {
        width: 75px;
        height: 75px;

    }

}

@media(max-width: 450px) {

    .label,
    .info {
        font-size: 0.75rem;
    }
}

#background::-webkit-scrollbar{
    display: none;
}
#message[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-placeholder);
}

.focus-border-none:focus {
  border: none;
  outline: none;
}

div#message::-webkit-scrollbar-thumb {
  background: #8CC0E3 !important;
}

[data-rmiz-btn-open] {
   cursor: -webkit-zoom-in !important;
}

[data-rmiz-btn-close] {
   cursor: -webkit-zoom-out !important;
}

.text-transform-none {
   text-transform: none !important;
}

.justify-content-end {
   -webkit-justify-content: flex-end !important;
}

.bg-color-blue {
   background-color: rgb(71,155,210) !important;
}

.remove-bg .MuiSelect-select:focus {
   background: none !important;
}

.remove-active-border:active , .remove-active-border:focus {
  border: none !important;
  outline: none !important;
}

.container{
    display: flex;
    /* margin-top: 10px; */
}

body{
  font-family: 'Source Sans Pro', sans-serif;
}

.singinBg {
   background-image: url(./assets/signinbg.jpg);
   background-repeat: no-repeat;
   background-size: cover;
}

.dataGridScroll {
   /* margin-bottom: 2vh; */
   border-radius: 25px !important;
   border: none !important;
   overflow-y: auto;
   padding: 0 10px;
   height: 100% !important;
   background-color: #fff;
}

.dataGridScroll::-webkit-scrollbar-thumb {
   background-color: #479BD2;  
   width: 6px;
   border-radius: 20px;  
}

.dataGridScroll::-webkit-scrollbar-track {
   background: #eee;
   margin: 10px 0;
   border-radius: 20px;
}

.scroll-blue::-webkit-scrollbar-thumb {
  background-color: #479BD2;  
  width: 6px;
  border-radius: 20px;  
}


.link{
    text-decoration: none;
    color: inherit;
}

/* dashboard guildeline scrollbar */
.dashboard-scroll::-webkit-scrollbar {
   width: 8px;
   height: 5px;
}

.dashboard-scroll::-webkit-scrollbar-track {
   background: #eee;
}
 
.dashboard-scroll::-webkit-scrollbar-thumb {
   background-color: #479BD2;  
   width: 6px;
   border-radius: 20px;  
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;        /* color of the tracking area */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #aaa;    /* color of the scroll thumb */
    width: 6px;
    border-radius: 20px;       /* roundness of the scroll thumb */
    /* border: 3px solid #aaa;  creates padding around scroll thumb */
  }

  .MuiDataGrid-row
  {
    background-color: white;
    text-align: center;
  }

  /* .MuiDataGrid-row:hover,  */
  .MuiDataGrid-row:hover{
    background-color: #1b5c95;
  }

  .MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer{
    text-align: center;
  }

  .workflow-name, .workflow-sub-heading{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  .workflow-name{
    color: #334D6E;
    font-size: 20px;
    /* line-height: 30px; */
  }

  .workflow-sub-heading{
    color: #90A0B7;
    font-size: 16px;
    line-height: 19px;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #334D6E;
  }

  .MuiDataGrid-root .MuiDataGrid-cell--textLeft{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #404040;
  }

  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 25px;
  }

  .MuiDataGrid-root .MuiDataGrid-iconSeparator{
    display: none;
  }

  button:hover{
    cursor: pointer;
  }

  .closeIcon{
    border-radius: 50% !important;
  }

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';

/* first render style fix */

div.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

span.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  padding: 9px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,-webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

ul.MuiList-root li.MuiButtonBase-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
}
.selected {
  border: 5px solid !important;
  border-color: white !important;
  background-color: #377ca3 !important;
  color: white !important;
}

.option{
  /* box-shadow: none;
  transition: box-shadow 0.2s; */
}

.option:hover {
  /* box-shadow: 
    0 -5px 0 0 rgb(50 50 50 / 50%),
    -5px 0 0 rgb(50 50 70 / 50%),
    0 5px 10px 0 rgb(0 5 7 / 25%),
    0 5px 10px 0px rgb(0 5 7 / 25%); */

  background-color: rgba(140, 208, 248, 0.3) !important;
}

#multiSelectQuestion {
    background: #fff;
    border-radius: 2vh;
    box-shadow: 0.1rem 0.5rem 1rem grey;
    width: 50vw;
    margin-top: 1vh;

    .options {
        list-style: none;
        padding: 2vh 4vw 5vh;
        display: flex;

        .multi-select-options-content {
            margin: 0;
            max-width: 50%;
        }
    }
}

@media only screen and (max-width: 600px) {
    #multiSelectQuestion {
        width: auto;

        .options {
            flex-direction: column;
            padding: 2vh 4vw 5vh 8vw;

            .multi-select-options-content {
                margin-top: 20px;
                max-width: 100%;
            }
        }
    }
}
.change-password {
    display: flex;
	flex-direction: column;
	margin-top: 50px;
	position: relative;
	flex: 1;

    .change-password-actions {
		position: absolute;
		bottom: 0;
		right: 0;

		> div {
			color: #fff;
			width: 200px;
			padding: 5px 0;
			text-align: center;
			border-radius: 20px;
			background-color: #479BD2;
			font-weight: 700;
			cursor: pointer;

			&:last-child {
				margin-right: 25px;
			}
		}
	}

    .input-group {
		display: flex;
		align-items: center;
		margin-bottom: 60px;

		input {
			width: 250px;
			outline: none;
			border: none;
			background: transparent;
			border-bottom: 1px solid #818181;
			font-size: 14px;
			font-family: inherit;
			color: #a4a4a4;
            padding: 5px 0;
		}
	}
}
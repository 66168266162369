.paper {
    width: 60vh;
    margin: 1rem auto;
    padding: 2rem;
    background: #fff;
    /* border-radius: 30px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}


.sideImage {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -100;
}

@media (max-width: 600px) {
    .sideImage {
        display: none;
    }

    .paper {
        width: 85vw;
        overflow-x: hidden;
    }
}
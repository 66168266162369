#helperSection {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 70vw;
    margin: 0 0 2vh 64px;
    z-index: 2;

    #chatInput {
        width: 68vw;
        bottom: 5px;

        input {
            font-size: 1.2rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    #helperSection {
        margin: 0 0 4vh;
        width: auto;

        #chatInput {
            width: 92vw;
            bottom: 10px;

            input {
                font-size: 12px;
            }
        }
    }
}
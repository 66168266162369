.container-team {
  /* width: 76vw; */
  align-items: center;
  justify-content: center;
}

.role-select .MuiSelect-select {
   font-family: 'Mulish';
   font-size: 14px;
}

.role-select .MuiSelect-select:focus {
   background: none !important;
}

.heading-team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.report-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #E4E4E4;
  padding: 8px 0;
  border-radius: 25px;
}

.active-report-tab {
  background-color: #479BD2;
  color: #fff;
  font-weight: 700;
}

.report-tabs div {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
}

.heading-team div {
  margin: 0px;
}

.heading-team .select {
  margin: 0 0 0 20px;
  width: 200px;
}

input:focus{
  border: none;
  outline: none;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 18px;
} */

.header-team {
  display: flex;
  margin: 15px 0 0;
  background: #ccc;
  border-radius: 25px 25px 0px 0px;
}

.header-column-team {
  width: 11vw;
  /* text-align: center; */
  padding: 15px 0 15px 1vw;
  font-weight: 600;
}

/* .row-team {
  display: flex;
  background: #fff;
  border: 1px solid #ddd;
  align-items: center;
} */

.row-team {
   margin: 0 0 40px 0;
   background-color: #eee;
   padding: 10px;
}

.row-team:last-child {
   margin-bottom: 0;
}

.row-column-team {
  width: 11vw;
  padding: 15px 0 15px 1vw;
  word-break: normal;
}

.header-column-team1 {
  width: 14vw;
  /* text-align: center; */
  padding: 15px 0 15px 1vw;
  font-weight: 600;
}

.row-column-team1 {
  width: 14vw;
  padding: 15px 0 15px 1vw;
  word-break: break-all;
}
.question-sequence-item {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: #c4c4c4;
    height: 50px;
    width: 50px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10%;
    margin-top: 15px;
    position: relative;

    &:nth-child(5n), &:last-child {
        margin-right: 0;

        &:after {
            content: none;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #c4c4c4;
        left: 50px;
        top: 24px;
    }

    &.done {
        background-color: #479BD2;

        &:after {
            background-color: #479BD2;
        }
    }
}

.ps-bot {
    background: rgb(15, 113, 140);
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 40px;
    box-sizing: border-box;
    position: relative;

    &-header {
        display: none;
        padding: 0 15px;
    }

    &-header-logo {
        flex: 1;
        text-align: center;
    }

    &-left {
        margin-right: 40px;
        flex: 4 1 0%;
        display: flex;
        flex-direction: column;
    }

    &-details {
        margin-bottom: 20px;
        background: rgb(255, 255, 255);
        border-radius: 32px;
        flex: 1 1 0%;
        padding: 30px 25px;
        overflow-y: auto;
    }

    &-nav {
        background: rgb(255, 255, 255);
        border-radius: 32px;
        padding: 25px;
    }


    &-right {
        background: rgb(255, 255, 255);
        border-radius: 32px;
        flex: 6 1 0%;
        padding: 15px 30px 35px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &-right-header {
        border-bottom: 1px solid rgba(180, 171, 171, 0.66);
    }

    &-drawer {
        max-width: 300px;
    }

    &-drawer-head {
        display: flex;
        justify-content: flex-end;
        background-color: #fff;
    }

    &-drawer-paper {
        width: 300px;
        background-color: #0F718C !important;
    }

    &-drawer-body {
        padding: 15px;
        padding-left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &-questionlist {
        flex: 1;

        .visited, .not-visited {
            padding: 10px;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
        }

        .visited {
            background-color: #40A1BC;
            color: #fff;
        }

        .not-visited {
            background-color: #fff;
            color: #40A1BC;
        }
    }

    &-username {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 15px;
    }

    &-usermail {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
    }
}

.ps-msg {
    display: flex;
    margin-top: 20px;
    width: 70%;

    &-question {
        flex: 1;
    }

    &-icon {
        margin-right: 15px;
    }
}

.ps-mymsg {
    background-color: rgb(248, 248, 248);
    padding: 10px 30px;
    border-radius: 25px;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    .ps-msg {
        width: 100%;

        &-icon {
            margin-right: 0;
        }
    }

    .ps-bot {
        padding: 0;

        &-header {
            display: flex;
            position: fixed;
            background-color: #fff;
            align-items: center;
            top: 0;
            padding: 10px 0;
            left: 0;
            width: 100%;
            height: 40px;
            z-index: 5;
        }

        &-left {
            display: none;
        }

        &-nav {
            display: none;
        }

        &-right {
            border-radius: 0;
            padding: 45px 10px 10px;
        }

        &-right-header {
            display: none;
        }
    }

    .ps-mymsg {
        font-size: 15px;
    }
}
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

.navLink a {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.25rem;
    padding: 0;
    /* text-transform: uppercase; */
    color: #fff;
    margin-right: 1.2rem;
    opacity: 1;
    padding: 0 1.25rem;
}

.navLink a:hover {
    color: rgba(255, 255, 255, 0.801);
    text-decoration: none;
    opacity: 0.9;
    cursor: pointer;
}

/* .menuItemsImg {
    height: '2rem';
    display: 'block';
    padding-top: '0.5rem';
    margin-right: '1.5rem';
    padding-left: '1rem';
}

.menuItemsSubtitle {
    color: '#9e8f8e';
    margin: '0';
    font-size: '0.75rem';
} */

@media(max-width: 768px) {
    .navLink a {
        display: block;
        margin: 1rem 1.2rem;
        padding: 0.5rem 0;
        border-bottom: 1px solid #c4c4c444;
    }

    .brandLogo {
        margin: 1.25rem auto;
        display: block;
    }
}
.sidebar {
  width: 20vw;
  min-height: 100vh;
  height: 100%;
  /* background-color: rgb(251, 251, 255); */
  background: #fff ;
  /* position: fixed; */
  overflow-y: scroll;
  /* top: 50px; */
  box-shadow: 2px 0 8px 5px rgba(0,0,0,0.1);
}

.user-div{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  /*justify-content: space-around;*/
}

.user-img{
  width: 3vw;
  height: 3vw;
  background-image: url(../../assets/user.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 1.5vw;
}

.user-details{
  margin-top: 5px;
}

/* .user-details h4, p{
  font-size: 1.7vh;
} */

.MuiButton-label{
  font-size: 1.5vh;
}

.customModal-change-pwd .MuiButton-label{
  font-size: inherit;
}

.logo-div{
  /* border: 1px solid red; */
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.sidebarWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: baseline; */
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarMenu button span {
   font-size: 14px;
   font-weight: 600;
}

.activesidebarMenu button span {
  color: #109CF1;
}

.sidebarTitle {
  font-size: 0.8vw;
  color: #109CF1;
}

.sidebarList {
  list-style: none;
  padding: 5px;
  font-size: 0.8vw;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
  border-radius: 10px;
  color: #109CF1;
  margin: 5px;
  margin-left: 30px;
  font-weight: 600;
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}

.MuiButton-root{
  text-transform: capitalize !important;
}

.tools{
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.activeSidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  /* background-color: rgb(240, 240, 255); */
  color: #555;
  margin: 3px 5px;
  margin-left: 30px;
  font-weight: 600;
  color: #109CF1 !important;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.link{
  color: #109CF1;
}

#chart{
  margin: 10vh 0px;
  width: 150px; 
  height: 150px; 
  position: relative; 
  top: 100px; 
  left: -90px;
}

.logo{
  height: 3.5vh;
  width: 11vw;
}

/* @media screen and (max-width: 1280px){
  .logo{
    width: 11vw;
    height: 3vh;
  }

  .logo-div{
    padding: 0px 0px 10px 0px;
  }

  .chart{
    margin: 0;
    top: 0;
    left: -130px;
  }
} */

/* @media screen and (max-width: 1366px){
  .logo{
    width: 11vw;
    height: 3vh;
  }

  .logo-div{
    padding: 0px 0px 10px 0px;
  }

  .chart{
    margin: 0;
    top: 0;
    left: -125px;
  }
} */

/* @media screen and (max-width: 1600px) {
  .user-div{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user-img{
    width: 100px;
    height: 100px;
  }

  .user-details{
    text-align: center;
  }

  .chart{
    margin: 6vh 0px;
  }
} */

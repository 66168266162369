:root {
	--primary-light: #1344bc;
	--primary: #0A2463;
	--primary-dark: #06173f;

	--secondary-light: #96ce96;
	--secondary: #62B662;
	--secondary-dark: #499c49;

	--white: #FFFFFF;
	--greyLight-1: #E4EBF5;
	--greyLight-2: #6a6666;
	--greyLight-3: #bec8e4;
	--greyDark: #9baacf;
	--black: #000;
	--shadow: 0rem 0rem 0rem .2rem var(--greyLight-2),
		0rem 0rem .6rem .2rem var(--black);
	--shadow-secondary: 0rem 0rem 0.3rem .5rem #0f3f79,
		-.2rem -.4rem 1.6rem #93c4ff;
	;


}

.arrow {
	box-sizing: border-box;
	height: 1vw;
	width: 1vw;
	border-style: solid;
	border-color: white;
	border-width: 0px 1px 1px 0px;
	transform: rotate(45deg);
	transition: border-width 150ms ease-in-out;
	margin-left: 5vh;
	padding: 2px 0;
}

.arrow:hover {
	border-bottom-width: 4px;
	border-right-width: 4px;
}

.btn {
	width: 15rem;
	height: 3rem;
	border-radius: 1.5rem;
	box-shadow: .3rem .3rem .6rem var(--greyLight-2),
		-.2rem -.2rem .5rem var(--white);
	;
	justify-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: .3s ease;

}

.btn__primary {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--primary);
	box-shadow: inset .2rem .2rem 1rem var(--primary-light),
		inset -.2rem -.2rem 1rem var(--primary-dark),
		var(--shadow);
	color: var(--greyLight-1);
}

.btn__primary1 {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--greyLight-2);
	box-shadow: inset .2rem .2rem 1rem var(--greyLight-3),
		inset -.2rem -.2rem 1rem var(--primary-dark),
		var(--shadow);
	color: var(--primary);
}

.btn__primary2 {
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--greyLight-1);
	box-shadow: inset .2rem .2rem 1rem var(--primary-light),
		inset -.2rem -.2rem 1rem var(--greyDark),
		var(--shadow);
	color: var(--primary);
}

.btn__secondary {
	width: 15rem;
	height: 4vh;
	margin: auto;
	border-radius: 2vh;
	grid-column: 1 / 2;
	grid-row: 4 / 5;
	background: var(--secondary);
	box-shadow: inset .2rem .2rem 1rem var(--secondary-light),
		inset -.2rem -.2rem 1rem var(--secondary-dark),
		var(--shadow-secondary);
	color: white;
	padding: 0.4vh 1.4vw;
	text-transform: capitalize;
	font-size: 1.1vw
}




.btn:hover {
	color: var(--white);
}

.btn:active {
	color: var(--white);
}

.btnColor1 {
	color: #2F55AF;
}

.btnColor2 {
	color: #0C95BA;
}

.btnColor3 {
	color: #10054D;
}

.btnColor4 {
	color: #3AB9D6;
}

.btnColor5 {
	color: #000000;
}

.btnColor6 {
	color: #131B23;
}

.btnColor7 {
	color: #DBDBDB;
}

.btnColor8 {
	color: #E9EEF3;
}

.btnColor9 {
	color: #0E6495;
}

.btnColor10 {
	color: #0E6495;
}

.btnColor11 {
	color: #0F3F79;
}

.btnColor12 {
	color: #25054D;
}

.btnColor13 {
	color: #42B6D6;
}

.btnColor14 {
	color: #DC3545;
}

.btnColor15 {
	color: #666666;
}

.btnColor16 {
	color: #62B662;
}

.btnColor17 {
	color: #C4C4C4;
}

.btnColor18 {
	color: #92E7FF;
}

.btnColor19 {
	color: #99FBFF;
}

.btnColor20 {
	color: #0D95BB;
}

.btnColor21 {
	color: #CCCCCC;
}

.btnColor22 {
	color: #D9D9D9;
}

.btnColor23 {
	color: #EE4057;
}

.btnColor24 {
	color: #359FAF;
}

.btnColor25 {
	color: #D443A2;
}

.btnColor26 {
	color: #7121FF;
}
#testGuidelinesPage {
    background-image: linear-gradient(to right, #23597C, #008095);
    min-height: 100vh;
    width: 100vw;
    height: 100%;
    margin: 0;

    .test-guidelines-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        flex-direction: row;
    }

    .test-guidelines-welcome {
        margin: 15px 0;

        h2 {
            font-size: 30px;
            color: #fff;
        }
    }

    .test-guidelines-content {
        margin-bottom: 30px;

        .test-guidelines-content-scroll {
            height: 320px;
            overflow: auto;
            padding: 0 40px;
            margin-right: 10px;
        }
    }

    .test-guidelines-actions {
        padding: 20px 40px;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .test-guidelines-start button {
            padding: 5px 50px;
            background: #008095;
            font-weight: bold;
            font-family: 'Poppins';
            letter-spacing: 0.01em;
            border-radius: 15px;
            padding: 5px 50px;
            font-size: 16px;
            background-image: linear-gradient(to right, #23597C, #008095);
        }
    }
}

@media only screen and (max-width: 600px) {
    #testGuidelinesPage {
        .test-guidelines-header{
            flex-direction: column;
        }

        .test-guidelines-welcome {
            margin: 15px 0 25px;

            h2 {
                font-size: 20px;
            }
        }

        .test-guidelines-content {
            margin-bottom: 20px;

            h2 {
                font-size: 20px;
            }

            .test-guidelines-content-scroll {
                height: 50vh;
                overflow: auto;
                padding: 0 20px;
                margin-right: 0px;
                font-size: 14px;
            }
        }

        .test-guidelines-actions {
            padding: 15px 0;
            font-size: 12px;

            .test-guidelines-pp a span.pp-accept {
                display: none;
            }

            .test-guidelines-pp a span.pp-privacy {
                text-transform: capitalize;
            }

            .test-guidelines-start button {
                padding: 5px 20px;
            }
        }
    }
}
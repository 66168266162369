#chatbotSuccess {
    > div {
        flex-direction: row;

        .chatbot-success-msg {
            max-width: 40%;
            margin-left: 15px;
        }
    }
}

@media only screen and (max-width: 600px) {
    #chatbotSuccess {
        > div {
            flex-direction: column;

            .chatbot-success-msg {
                max-width: 100%;
                margin-left: 0;
            }
        }
    }
}
.check-btn:hover {
	color: #81DB57;
}

.cancel-btn:hover {
	color: red;
}

.visibility-btn:hover,
.article-btn:hover {
	color: #479BD2;
}

.check-btn,
.cancel-btn,
.visibility-btn {
	color: #C4C4C4;
}

.MuiDataGrid-iconSeparator,
.MuiSvgIcon-root {
	display: none;
}

#scroll-down-animation {
	position: absolute;
	top: 380px;
	padding: 8px 20px;
	border-radius: 30px;
	background: #479BD2;
	color: #fff;
	animation: scrollDown 2s infinite;
	z-index: 5;
}

#scroll-down-animation:before {
	content: '';
	display: block;
	background: #479BD2;
	height: 18px;
	width: 18px;
	position: absolute;
	bottom: 0;
	right: 50%;
	z-index: 1;
	transform: translate(50%, 9px) rotateZ(45deg);
}


@keyframes scrollDown {
	0% {
		top: 380px;
	}

	50% {
		top: 400px;
	}

	100% {
		top: 380px;
	}
}

.carousel-container {
	margin: auto;
	padding: 0 10px;
	position: relative;
	/* text-align: center; */
}

.owl-carousel .owl-nav {
	overflow: hidden;
	height: 0px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #5110e9;
}

.owl-carousel .item {
	/* text-align: center; */
}

.owl-carousel .nav-button {
	height: 45px;
	width: 45px;
	cursor: pointer;
	position: absolute;
	top: 150px;
	background-color: rgba(0, 0, 0, 0.7);
}

.owl-carousel .nav-button img {
	height: 45px;
	width: 45px;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
	pointer-events: none;
	opacity: 0.25;
}

.owl-carousel .owl-prev.nav-button {
	left: -40px;
	display: flex;
}

.owl-carousel .owl-next.nav-button {
	right: -35px;
	display: flex;
}

.owl-theme .owl-nav [class*=owl-] {
	color: #ffffff;
	font-size: 39px;
	background-color: transparent;
	border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
	color: #ffffff;
	font-size: 39px;
	background-color: transparent;
	border-radius: 3px;
}

.owl-carousel .prev-carousel:hover {
	background-position: 0px -53px;
}

.owl-carousel .next-carousel:hover {
	background-position: -24px -53px;
}

.owl-theme .owl-dots {
	display: none;
}
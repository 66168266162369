.button-div {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}

.modal-text {
  padding: 20px;
  font-size: large;
  font-weight: 400;
}

.modal-button {
  color: white !important;
  background: #0C95BA;
  margin-right: 3px !important;
}

.cancel-modal-button {
  color: white !important;
  background: #0e7794;
  margin-right: 15px !important;
}
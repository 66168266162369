.user-settings {
    padding: 0 20px;

    .wrapper {
        background: #fff;
        border-radius: 33px;
        box-shadow: 7px 9px 9px rgba(0, 0, 0, 0.25);
        margin: 0;
        padding: 10px 45px;

        .settings-header {
            border-bottom: 1px solid #ececec;
            display: flex;
            flex-direction: row;
            padding: 20px 0;

            > div {
                flex: 1;
                font-size: 18px;
                font-weight: 600;
                padding-left: 30px;
                margin-right: 30px;

                &:first-child {
                    margin-left: 90px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .settings-item {
            padding:  30px 0;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #ececec;
            align-items: center;

            .settings-name {
                font-weight: bold;
                color: #333;
                margin-left: 90px;
                margin-right: 30px;
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;

                > div:first-child {
                    margin-right: 20px;
                }
            }

            .settings-description  {
                font-weight: bold;
                color: #a4a4a4;
                margin-right: 40px;
                flex: 1;
            }

            .settings-action {
                flex: 1;

                > div {
                    color: #fff;
                    background-color: #479BD2;
                    border-radius: 20px;
                    font-weight: bold;
                    padding: 5px 20px;
                    display: inline-flex;
                    width: 100px;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }

    &__newlogo {
        max-width: 100%;
        max-height: 150px;
        margin-bottom: 15px;
      }
    
      &__logobox {
        text-align: center;
      }
    
      &__logoinputbox {
        position: relative;
    
        &-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          box-sizing: border-box;
          color: #A4A4A4;
          font-weight: bold;
          border: 2px solid #A4A4A4;
          border-radius: 10px;
          padding: 10px;
          z-index: 2;
        }
    
        &-input {
          position: absolute;
          height: 52px;
          margin: 0;
          outline: none;
          border-radius: 10px;
          z-index: 5;
          top: 0;
          left: 0;
          opacity: 0;
          right: 0;
          cursor: pointer;
        }
    
        &-label {
          font-size: 14px;
          color: #A4A4A4;
          margin: 15px;
        }
      }
    
      &__logomodal {
        width: 400px;
        overflow-y: unset;
        border-radius: 30px !important;
        padding: 20px 40px !important;
      }
}

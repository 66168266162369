/* .customModal{
    max-width: auto;
    background-color: #fff;
    width: 100%;
} */


.upload-card {
   text-align: center;
   background-color: #fff;
   margin: 0 15px;
   padding: 20px;
   padding-top: 40px;
   border-radius: 10px;

   font-size: 12px;
   color: rgb(50,50,50);
   /*font-weight: 600;*/

   box-shadow: 2px 5px 10px 2px rgba(0,0,0,0.3);
   /* height: 100%; */
   flex: 1;

   display: flex;
   flex-direction: column;
   position: relative;
}

.upload-editbox {
   position: absolute;
   top: 0;
   right: 0;

   width: 100%;
   display: flex;
   justify-content: flex-end;
}


.upload-card-imgbox img {
   width: auto;
   max-width: 150px;
   height: auto;
   max-height: 30px;
   padding-bottom: 10px;
}

.upload-card-subject , .upload-card-body-container {
   padding: 10px;
}

.upload-card-subject {
   text-align: center;
   font-size: 14px;
   font-weight: 600;
}

.upload-card-body-container {
   flex: 1;
   max-height: 200px;
   box-sizing: border-box;
   overflow: hidden;
}

.upload-card-body {
   max-width: 100%;
   font-size: 10px;
   text-align: left;
}

.upload-card-body:first-child {
   margin-bottom: 10px;
}

.upload-card-body:last-child { 
   margin-top: 10px;
}

#preview span {
   overflow-wrap: break-word;
}

.upload-card-body-2 {
   max-height: 200px;
}

.upload-card-body p{
   font-size: 10px;
}

.upload-card-body span{
   font-size: 10px !important;
}

.upload-card .upload-card-btn {
   margin-top: 15px;
}

.upload-title {
   text-align: center;
   margin-top: 15px;
}

.upload-title h3 {
   color: rgb(50, 50, 50);
   font-weight: 600;
}

.modal-input label {
   margin-left: 10px;
   font-size: 12px;
   font-weight: 600;
}

.add-card-container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.add-card {
   box-sizing: border-box;
   text-align: center;
   height: 50%;
   border-radius: 25px;
   background-color: #fff;
   margin: 0 15px;
   margin-bottom: 10px;
   padding: 0 20px;
   padding-top: 30px;
   box-shadow: 2px 5px 10px 2px rgba(0,0,0,0.3);
}

.add-card:last-child {
   margin-bottom: 0;
}

.add-card-wrapper {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.add-card p {
   font-weight: 600;
   color: rgb(50,50,50);
   font-size: 14px;
}
#chatMessageList {
    position: relative;
    height: 84vh;
    background: #fff;
    padding: 4vh 1vw 0;
    margin: 0 2vw;
    font-size: 13px;
    color: whitesmoke;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;

    .ChatbodyScrollbar {
        padding: 0px 1vw 20px;
        margin: 0 1vw 80px;
    }

    .message-row {
        grid-template-columns: 70%;
    }

    .other-message .message-content {
        grid-column-gap: 15px;
    }

    .other-message .new {
        max-width: 45vw;
        font-size: 16px;
    }

    .message-text {
        max-width: 45vw;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    #chatMessageList {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        height: calc(100vh - 160px);

        .message-row {
            grid-template-columns: 95%;
        }

        .other-message .message-content {
            grid-column-gap: 0px;
        }

        .message-text {
            max-width: none;
            font-size: 14px;
        }

        .other-message .new {
            max-width: none;
            font-size: 14px;
        }

        .ChatbodyScrollbar {
            padding: 0px 1vw;
            margin: 0 1vw;
        }
    }
}

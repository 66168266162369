.container-div{
    width: 76vw;
    height: 100vh;
    /* margin: 0 auto; */
}

.tablecell-span {
   font-family: 'Poppins';
   font-size: 16px;
   font-weight: 500;
   color: #334D6E;
}

.tablecell-data {
   font-family: 'Mulish';
   font-size: 14px;
   font-weight: 600;
   color: #404040;
}

.inner-top-div{
    display: flex;
    justify-content: space-between;
}

/* table{
    border: none;
} */

.inner-div-btn{
    background: #479BD2;
    border-radius: 17px;
    outline: none;
    border: none;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 8px;
    margin-top: 10px;
}

#btn1{
    margin-right: 20px;
}

.customModal-workflow{
    width: 620px;
}

.inner-div-details{
    font-family: Poppins;
}

.inner-div-details p{
    color: #90A0B7;
    font-size: 16px;
}

.inner-div-details h2{
    color: #334D6E;
    font-size: 24px;
}

.chart-outer-div{
    width: 76vw;
    margin: 0 auto;
    display: flex;
    background-color: white;
    height: 250px;
    padding: 25px;
    border-radius: 25px;
}

.all-applicant-div{
    border-radius: 25px;
    width: 76vw;
    background-color: white;
    height: 300px;
    padding: 25px;
    margin: 20px auto;
}

.all-applicants-top-div{
    margin-bottom: 15px;
}

.chart-inner-div, .all-applicant-title{
    display: flex;
}

.circle{
    height: 20px;
    width: 20px;
    outline: none;
    border: none;
    background-color: #F5F5F5;
}

.circle img{
    height: 14px;
    width: 14px;
    margin-left: 3px;
    margin-top: 3px;
}

.title{
    font-family: Mulish;
    margin-left: 15px;
    margin-top: -2px;
}

#chart1{
    width: 300px;
    margin-top: 50px;
    margin-left: 100px;
}

.chart-right-div table, .chart-right-div tr, .chart-right-div td{
    font-size: 20px;
}

.chart-right-div td{
    padding: 10px 15px;

}

.chart-right-div{
    margin: 30px auto;
}

.all-applicants-top-div{
    display: flex;
    justify-content: space-between;
}

.table-header, .table-body-row{
    display: flex;
}

.quick-menu-handler{
    width: 82vw;
    margin: 0 auto;
}
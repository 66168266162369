#chatSidebar {
	width: 15vw;
}

.chatbox-user-guide-img {
	width: 300px;
}

@media only screen and (max-width: 600px) {
	#chatSidebar {
		display: none;
	}

	.chatbox-user-guide-img {
		width: 200px;
	}
}

.create-job-opening {
    .copy-jd-link {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #dfdfdf;
        }
    }

    .create-job-loader {
        border: 4px solid #f3f3f3;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border-top: 4px solid #555;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        position: absolute;
        left: calc(50% + 80px);
        top: 0;
    }

    .modal-checkbox {
        color: #656565;
        font-size: 16px;
        font-weight: 500;
        position: relative;
    }
    
    .modal-checkbox label {
        padding-left: 44px;
    }
    
    .modal-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    .modal-checkbox a {
        margin-top: 40px;
        color: #656565;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        margin-bottom: 25px;
    }
    
    .modal-checkbox .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #656565;
        cursor: pointer;
    }
    
    .modal-checkbox .checkmark:hover input~.checkmark {
        background-color: #ccc;
    }
    
    .modal-checkbox input:checked~.checkmark {
        background-color: #479bd2;
    }
    
    .modal-checkbox .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .modal-checkbox input:checked~.checkmark:after {
        display: block;
    }
    
    .modal-checkbox .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }    
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.trial-users-list {
	width: 100%;

	.header {
		display: flex;

		> div {
			padding: 5px 15px;
			border: 1px solid #ccc;
			border-right: none;
			font-weight: bold;
			width: 10%;

			&:last-child {
				border-right: 1px solid #ccc;
			}

			&:first-child {
				width: 5%;
			}

			&:nth-child(2) {
				width: 15%;
			}

			&:nth-child(8) {
				width: 12%;
			}

			&:nth-child(9) {
				width: 8%;
			}
		}
	}

	.rows {
		> div {
			padding: 5px 10px;
			border: 1px solid #ccc;
			border-right: none;
			word-break: break-all;
			width: 10%;

			&:last-child {
				border-right: 1px solid #ccc;
			}

			&:first-child {
				width: 5%;
			}

			&:nth-child(2) {
				width: 15%;
			}

			&:nth-child(8) {
				width: 12%;
			}

			&:nth-child(9) {
				width: 8%;
			}
		}
	}
}

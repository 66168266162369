.selected {
  border: 5px solid !important;
  border-color: white !important;
  background-color: #377ca3 !important;
  color: white !important;
}

.option{
  box-shadow: none;
  transition: box-shadow 0.4s;
}

.option:hover {
  box-shadow: 
    0 -5px 0 0 rgb(50 50 50 / 75%),
    -5px 0 0 rgb(50 50 70 / 75%),
    0 5px 10px 0 rgb(0 5 7 / 60%),
    0 5px 10px 0px rgb(0 5 7 / 45%);
}

.SubmitButton {
  background: rgba(217, 243, 252, 70%) !important;
  color: rgb(8, 105, 184) !important;
  padding: 4px 30px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  border-radius: 20px !important;
  margin-top: 1vh !important;
  margin-bottom: 1vh !important;
  margin-right: 2vw !important;
  text-transform: none !important;
}

.toggle-case{
  list-style: none;
  display: flex;
  margin-bottom: 10px;
}

.toggle-case li{
  margin-right: 10px;
  cursor: pointer;
}

.toggle-case .active {
  color: orange;
  text-decoration: underline orange;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer{
  text-align: center;
}

.react-responsive-modal-modal{
  border-radius: 34px;
}

.container-team{
  margin: 25px;
}

/* @media screen and (min-width: 1366px){
  .userList{
    width: 1000px;
  }
}

@media screen and (min-width: 1280px){
  .userList{
    width: 1000px;
  }
} */
/* .spreadSheet .Spreadsheet {
   position: relative;
   width:100%;
   z-index: 1;
}

.spreadSheet .Spreadsheet th {
   background: #ccc;
}

.spreadSheet .Spreadsheet th:first-child {
   position: -webkit-sticky;
   position: sticky;
   left: 0;
   z-index: 2;
   background: #ccc;
}

.spreadSheetModal {
   max-width: 90vw !important;
   width: 85vw;
   text-align: center !important;
}

.spreadSheetBtn {
   text-transform: none !important;
}

.spreadSheet table, .spreadSheet tr, .spreadSheet td {
   font-size: 13px !important;
} */

.react-responsive-modal-modal.syncSpreadSheetModal {
   width: 90vw;
   max-width: 90vw;
}

.react-responsive-modal-modal.syncSpreadSheetModal .react-responsive-modal-closeButton {
   background: #E44E4E;
   color: #fff;
   border-radius: 50%;
   padding: 5px;
}
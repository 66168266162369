.verticalCarousel {
	margin-bottom: 20px;

	&__stage {
		height: 35vh;
		overflow: hidden;
	}

	&__wrapper {
		width: 13vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: transform 0.5s;
	}

	&__item {
		box-sizing: border-box;
		padding: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&:first-child {
			padding-top: 0;
		}
	}

	&__item img {
		width: 100%;
		max-height: 100%;
	}

	&__next,
	&__prev {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 40px;
			transform: rotate(-90deg);
			cursor: pointer;
		}
	}

	&__prev {
		img {
			transform: rotate(90deg);
		}
	}
}

//Zoom exhibits styling fix

[data-rmiz-modal-content] {
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}
